<template>
	<div class="app-container">
        <!-- tab切换 -->
		<el-tabs v-model="listState" @tab-click="search" v-if="listStateList[1].num">
			<el-tab-pane
				v-for="item in listStateList"
				:key="item.id"
				:label="item.value + '(' + item.num + ')'"
				:name="item.id+''"
			></el-tab-pane>
		</el-tabs>

        <!-- API商品搜索条件 -->
        <div class="filter-container" v-if="listState == 1">
            <el-form ref="ruleForm" :inline="true" :model="ruleForm" label-width="120px" style="max-width:1300px;">
                <el-form-item label="商品编码：">
                    <el-input v-model="ruleForm.goodsCode"></el-input>
                </el-form-item>
                <el-form-item label="商品名称：">
                    <el-input v-model="ruleForm.goodsName"></el-input>
                </el-form-item>
                <el-form-item label="商品条码：">
                    <el-input v-model="ruleForm.barCode"></el-input>
                </el-form-item>
                <el-form-item label="供应商：">
                    <el-select v-model="ruleForm.supplierId" style="width: 178px;margin-right: 10px;">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in supplierList" :key="item.id" :label="item.supplierName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品分类：">
                    <el-select v-model="ruleForm.categoryId" style="width: 178px;margin-right: 10px;">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-for="item in goodsTypeList" :key="item.id" :label="item.categoryName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品类型：">
                    <el-select v-model="ruleForm.goodsType" style="width: 178px;margin-right: 10px;">
                        <el-option v-for="item in goodsTypes" :key="item.id" :label="item.label" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="供货价：">
                    <el-input v-model="ruleForm.beginCostPrice" style="width:80px;"></el-input> - 
                    <el-input v-model="ruleForm.endCostPrice" style="width:80px;"></el-input>
                </el-form-item>
                <el-form-item label="商品状态：">
                    <el-select v-model="ruleForm.goodsStatus" style="width: 178px;margin-right: 10px;">
                        <el-option v-for="item in goodsStatus" :key="item.id" :label="item.label" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上下架时间：">
                    <el-date-picker
                        v-model="ruleForm.searchTime"
                        type="datetimerange"
                        range-separator="～"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                </el-form-item>
            </el-form>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="search">筛选</el-button>
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="resetForm">重置</el-button>
			</div>
		</div>
        <div class="filter-container" style="display: flex;justify-content: space-between;" v-if="listState == 2">
			
		</div>
		<!-- API商品表格 -->
		<div class="table-container">
            <div v-show="listState == 1">
                <el-table 
                    :data="tableData" 
                    v-loading="loading" 
                    border
                    @sort-change="sortChange"
                    style="width: 100%">
                    <el-table-column prop="goodsCode" label="商品编码" width="100"></el-table-column>
                    <el-table-column prop="supplierName" label="供应商">
                        <template slot-scope="scope">
                            <span v-for="supplier in supplierList" :key="supplier.id">
                                <span v-if="scope.row.supplierId == supplier.id">{{supplier.supplierName}}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goodsName" label="商品名称"></el-table-column>
                    <el-table-column label="商品主图">
                        <template slot-scope="scope">
                            <div class="product-info" style="align-items:flex-start">
                                <img :src="scope.row.thumbImgUrl" style="width:80px;height:80px;"/>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="访问量" width="120">
                        <template slot-scope="scope">
                            <div>访客数:{{scope.row.visitUserCount}}</div>
                            <div>浏览量:{{scope.row.visitCount}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="salePrice" label="价格" width="100" sortable>
                        <template slot-scope="scope">
                            <div style="display:flex;justify-content:flex-start;align-items:center;">
                                <div style="white-space: nowrap;margin-right: 5px;color:#F56C6C;">￥{{scope.row.salePrice}}</div>
                                <buttonPermissions :datas="'updateGoodsPrice'">
                                    <i class="el-icon-edit-outline" @click="goodsEdit(scope.row,1)"></i>
                                </buttonPermissions>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalStock" label="库存" width="100" sortable>
                        <template slot-scope="scope">
                            <div style="display:flex;justify-content:flex-start;align-items:center;">
                                <div style="white-space: nowrap;margin-right: 5px;">{{scope.row.totalStock}}</div>
                                <buttonPermissions :datas="'updateGoodsStock'">
                                    <i class="el-icon-edit-outline" @click="goodsEdit(scope.row,2)"></i>
                                </buttonPermissions>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="soldCount" label="真实销量" sortable width="120"></el-table-column>
                    <el-table-column prop="virtualSoldCount" label="虚拟销量" width="100">
                        <template slot-scope="scope">
                            <div style="display:flex;justify-content:flex-start;align-items:center;">
                                <div style="white-space: nowrap;margin-right: 5px;">{{scope.row.virtualSoldCount}}</div>
                                <buttonPermissions :datas="'updateShelfStatus'">
                                    <i class="el-icon-edit-outline" @click="goodsEdit(scope.row,3)"></i>
                                </buttonPermissions>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column width="120">
                        <template slot="header">
                            热力值
                            <el-tooltip class="item" effect="dark" placement="top" style="font-size: 20px;color: #e6a23c;">
                                <div slot="content">
                                    热力值=访问量+访问人数+支付人数+基础值 <br/>
                                    只能修改基础值
                                </div>
                                <i class="el-icon-question"></i>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">
                            <div style="display:flex;justify-content:flex-start;align-items:center;">
                                <div style="white-space: nowrap;margin-right: 5px;">
                                    {{scope.row.basicHeatValue + scope.row.payNums + scope.row.visitUserCount + scope.row.visitCount}}
                                </div>
                                <buttonPermissions :datas="'editBasicHeatValue'">
                                    <i class="el-icon-edit-outline" @click="goodsEdit(scope.row,4)"></i>
                                </buttonPermissions>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onOffShelfTime" label="上下架时间" width="100"></el-table-column>
                    <el-table-column label="上架状态" width="120">
                        <template slot-scope="scope">
                            <buttonPermissions :datas="'reviseProduct'" v-if="scope.row.shelfStatus == 0">
                                <el-switch
                                    v-model="scope.row.shelfStatus"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    @change="displyFun(scope.row)"
                                ></el-switch>
                            </buttonPermissions>
                            <buttonPermissions :datas="'reviseProduct'" v-else-if="scope.row.shelfStatus == 1">
                                <el-switch
                                    v-model="scope.row.shelfStatus"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949"
                                    @change="displyFun(scope.row)"
                                ></el-switch>
                            </buttonPermissions>
                            <span>{{scope.row.shelfStatus == 0?'已下架':'已上架'}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <button-permissions :datas="'addCpsGoods'">
                                <div class="table-button" @click="eidtGroup(scope.row)">编辑</div>
                            </button-permissions>
                            <buttonPermissions :datas="'deleteProduct'" v-if="scope.row.shelfStatus == 0">
                                <el-button
                                    type="text"
                                    @click="delGoods(scope.row)"
                                    class="table-button"
                                    style="color:#f56c6c;"
                                >删除</el-button>
                            </buttonPermissions>
                            <button-permissions :datas="'goodsvoucherTg'">
                                <div type="text" class="pointers" style="color: #409EFF;" @click="handleTablePromote(scope.row.id)">推广</div>
                            </button-permissions>
                        </template>
                    </el-table-column>
                </el-table>
                <div>
                    <el-pagination
                        v-if="total"
                        style="margin:20px 0;float:right;"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNo"
                        :page-sizes="[10, 20, 30, 40, 50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                    ></el-pagination>
                </div>
            </div>
            
            <!-- CPS商品表格 -->
            <div v-show="listState == 2">
                
            </div>
		</div>





        <!-- 商品价格编辑的弹框 -->
		<el-dialog
			:visible.sync="editPriceVisable"
			width="800px"
			:title="dialogTitle"
			v-loading="specLoading"
		>
			<!-- <div style="margin-bottom: 20px;border-top:1px solid #ddd;padding-top: 15px;">商品:{{productNames}}</div> -->
			<el-form :model="dialogRuleForm" ref="dialogRuleForm">
				<div class="setPricebos" v-if="pricestocktype == 1 || pricestocktype == 2">
					<el-table :data="dialogRuleForm.specData" border style="width: 100%;">
						<el-table-column
							:prop="goodsSpec.specName"
							:label="goodsSpec.specName"
							v-for="(goodsSpec,index) in this.goodSpecsList"
							:key="index"
						></el-table-column>
						<el-table-column label="价格" v-if="pricestocktype == 1">
							<template slot-scope="scope">
								<el-form-item :inline-message="true" prop="salePrice">
									<el-input
										clearable
										v-model="scope.row.salePrice"
										style="width:100px;"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
									></el-input>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column label="库存" v-if="pricestocktype == 2">
							<template slot-scope="scope">
								<el-form-item :inline-message="true" prop="stock">
									<el-input
										clearable
										v-model="scope.row.stock"
										style="width:100px;"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
									></el-input>
								</el-form-item>
							</template>
						</el-table-column>
					</el-table>
				</div>
                <div v-else-if="pricestocktype == 3">
                    <el-form-item
						label="虚拟销量："
						:inline-message="true"
						prop="virtualSoldCount"
						:rules="[{required:true,message:'虚拟销量不能为空',trigger: 'blur'}]"
					>
                        <el-input
							v-model="dialogRuleForm.virtualSoldCount"
							style="width:180px;"
							@input="formatParseInt(dialogRuleForm,'virtualSoldCount')"
						></el-input>
					</el-form-item>
                </div>
                <div v-else>
                    <el-form-item label="访客数：" style="margin-bottom: 0px;" > {{priceEditdata.visitUserCount}}</el-form-item>
                    <el-form-item label="访问量：" style="margin-bottom: 0px;" > {{priceEditdata.visitCount}}</el-form-item>
                    <el-form-item label="支付人数：" style="margin-bottom: 0px;" > {{priceEditdata.payNums}}</el-form-item>
                    <el-form-item label="基础热力值：" style="margin-bottom: 0px;" > {{priceEditdata.basicHeatValue}}</el-form-item>
                    <el-form-item
						label="加减热力值："
						:inline-message="true"
						prop="upBasicHeatValue"
						:rules="[{required:true,message:'基础热力值不能为空',trigger: 'blur'}]"
					>
                        <el-input
							v-model="dialogRuleForm.upBasicHeatValue"
							style="width:180px;"
                            type="number"
						></el-input>
					</el-form-item>
                    <el-form-item label="热力值：" style="margin-bottom: 0px;" > 
                        {{Number(priceEditdata.basicHeatValue) + Number(priceEditdata.visitUserCount) + Number(priceEditdata.visitCount) + Number(priceEditdata.payNums) + Number(dialogRuleForm.upBasicHeatValue)}}
                    </el-form-item>
                </div>
				<div style="text-align: center;margin-top:30px;">
					<el-button
						type="primary"
						style="width: 120px;"
						:loading="loading"
						@click="savePriceBtn('dialogRuleForm')"
					>保存</el-button>
				</div>
			</el-form>
		</el-dialog>
		<!-- 推广的弹框 -->
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeShow = false"></batch-cods>
	</div>
</template>

<script>

import { 
    getApiGoodsCondition,
    queryApiGoodsInfoList,
    productChangePrice,
    productSpeChangeStock,
    productChangeSoldCount,
    updateHeatValue,
    productInfo,
    productOpen,
    productDel
} from '@/api/goods.js';
import batchCods from "@/components/bathCodes"

export default {
    name:'cpsGoods',
	components: {
        batchCods
	},
	data () {
		return {
			tableData: [],
			pageNo: 1,
			pageSize: 10,
			total: 0,
            loading: false,
            listState: '1',     //tab页下标
            listStateList: [    //tab页标题
				{ id: 0, value: '平台入驻', num: 0 },
				{ id: 1, value: '接口商品', num: 0 },
				{ id: 2, value: 'CPS商品', num: 0 },
            ],
            //API商品
            //搜索条件
            ruleForm:{
                barCode: "",
                beginCostPrice: "",
                categoryId: "",
                endCostPrice: "",
                goodsCode: "",
                goodsName: "",
                goodsType: "",
                marketChannel: "",
                supplierId: "",
                goodsStatus:0, 
                searchTime:[],
                salePriceSort:'',
                salesSort:'',
                totalStockSort:'',
            },
            //商品状态
            goodsTypes:[
                {id:"",label:'全部'},
                {id:0,label:'实物商品'},
                {id:1,label:'海淘商品'},
                {id:2,label:'虚拟商品'},
            ],
            goodsStatus:[
                {id:0,label:'全部'},
                {id:1,label:'销售中'},
                {id:2,label:'仓库中'},
                {id:3,label:'已售罄'},
                {id:4,label:'警戒'},
            ],
            marketChannelList:[],   //渠道
            supplierList:[],   //供应商
            goodsTypeList:[],   //分类数据
            specLoading: false,
            editPriceVisable: false, //是否显示价格编辑弹窗
            dialogTitle: '', //弹窗标题
            dialogRuleForm: {
				name: '',       //名称
				code: '',       //编码
                specData: [],   //规格数据
                virtualSoldCount:0, //虚拟销量
                upBasicHeatValue:0, //修改的热力值
            },
            pricestocktype: null,   //修改数据类型
            priceEditdata: {},  //当前编辑的商品
            weiCodeShow: false, //推广开关
			weiCodeData: {},    //推广数据
		}
	},
	created () {
        this.queryApiGoodsInfoList();
        this.getApiGoodsCondition();
	},
	methods: {
        //获取api商品查询数据
        async getApiGoodsCondition () {
			try {
                let result = await getApiGoodsCondition();
                this.supplierList = result.data.supplierList;
                this.goodsTypeList = result.data.goodsTypeList;
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
        },
        // 获取API商品列表数据
		async queryApiGoodsInfoList () {
			this.loading = true;
			try {
                if(this.listState != 1){
                    this.total = 0;
                    this.tableData = [];
                    return;
                }
                let data = JSON.parse(JSON.stringify(this.ruleForm));
                data.pageNo = this.pageNo;
                data.pageSize = this.pageSize;
                if (this.ruleForm.searchTime) {
					console.log(this.ruleForm.searchTime);
					data.startDate = this.ruleForm.searchTime[0];
					data.endDate = this.ruleForm.searchTime[1];
				}
				let result = await queryApiGoodsInfoList(data);
                if (result.success) {
                    result.data.records.map(item =>{
                        item.basicHeatValue = item.heatValue;
                        item.soldCount = item.soldCount ? item.soldCount : 0;
                        item.shelfStatus = item.shelfStatus == 1 ? true : false;
                    })

                    this.total = result.data.total;
                    this.listStateList[1].num = result.data.total;
                    this.tableData = result.data.records;
                }
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
        },
        //编辑
		eidtGroup (item) {
            let params = {
                id: item.id,
                isAPI:true
            }
            this.publicToDetails(params,'/goods/editGoodDetails',this);
            
		},
        //搜索
        search(){
            this.pageNo = 1;
            this.getDataByType();
        },
        //获取数据
        getDataByType(){
            if (this.listState == 0) {
                console.log(0);
            }else if (this.listState == 1){
                this.queryApiGoodsInfoList();
            }else if (this.listState == 2){
                console.log(2);
            }
        },
        //重置，并刷新
        resetForm() {
            this.tableData = [];
            this.ruleForm = {
                barCode: "",
                beginCostPrice: "",
                categoryId: "",
                endCostPrice: "",
                goodsCode: "",
                goodsName: "",
                goodsType: "",
                marketChannel: "",
                supplierId: "",
                goodsStatus:'', 
                searchTime:[],
                salePriceSort:'',
                salesSort:'',
                totalStockSort:'',
            }
            this.queryApiGoodsInfoList();
        },
        // 编辑商品数据
		async goodsEdit (item, type) {
			//type: 1修改价格，2修改库存，3修改实际销量，4修改虚拟销量
            let dialogTitle = '';
            this.dialogRuleForm.virtualSoldCount = 0;
			switch (type) {
				case 1:
					dialogTitle = '修改价格';
					break;
				case 2:
					dialogTitle = '修改库存';
					break;
				case 3:
                    dialogTitle = '修改虚拟销量';
                    this.dialogRuleForm.virtualSoldCount = item.virtualSoldCount;
                    break;
                case 4:
                    dialogTitle = '修改基础热力值';
                    this.dialogRuleForm.upBasicHeatValue = 0;
					break;
			}
			this.dialogTitle = dialogTitle;
			this.priceEditdata = item;
			this.pricestocktype = type;
			await this.getDataEdit();
			this.editPriceVisable = true;
		},

		async getDataEdit () {
			try {
				this.specLoading = true
				this.productNames = this.priceEditdata.Name;
				let result = await productInfo({
					id: this.priceEditdata.id
				})
				let data = result.data;
				//规格名
				let goodSpecsList = data.goodSpecsList;
				this.goodSpecsList = goodSpecsList;
				//规格明细
				let skuResponseList = data.skuResponseList;
				//组织规格明细数据
				this.dialogRuleForm.specData = [];
				skuResponseList.map(skuResponse => {
					let temp = {}
					goodSpecsList.map((goodSpecs, index) => {
						temp[goodSpecs.specName] = skuResponse.specValues[index];
					})
					temp.salePrice = skuResponse.salePrice;
					temp.stock = skuResponse.stock;
					temp.skuId = skuResponse.skuId;
					temp.virtualSoldCount = skuResponse.virtualSoldCount;
					this.dialogRuleForm.specData.push(JSON.parse(JSON.stringify(temp)));
				})
				console.log(this.dialogRuleForm.specData);
			} catch (e) {
				console.log(e)
			} finally {
				this.specLoading = false
			}
		},
		// 保存编辑弹窗中的内容
		savePriceBtn (formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					try {
						let data = {
							goodsId: this.priceEditdata.id,
							skuQuickEditRequestList: []
						}
						//处理规格数据
						this.dialogRuleForm.specData.map(item => {
							data.skuQuickEditRequestList.push({
								salePrice: item.salePrice,
								skuId: item.skuId,
								stock: item.stock,
								virtualSoldCount: 0
							});
                        })
                        data.skuQuickEditRequestList[0].virtualSoldCount = this.dialogRuleForm.virtualSoldCount;
						let result = '';
						switch (this.pricestocktype) {
							case 1:
								result = await productChangePrice(data);
								break;
							case 2:
								result = await productSpeChangeStock(data);
								break;
							case 3:
								result = await productChangeSoldCount(data);
                                break;
                            case 4:
                                data.heatValue = this.dialogRuleForm.upBasicHeatValue;
								result = await updateHeatValue(data);
								break;
						}

						if (result.success) {
							this.$message({
								type: 'success',
								message: '操作成功!'
							});
							this.editPriceVisable = false;
						}

					} catch (e) {
						console.log(e)
					} finally {
						this.getDataByType();
					}

				} else {
					console.log('error submit!!');
					return false;
				}
			});
        },
        // 推广-商品
		handleTablePromote (id) {
			this.weiCodeShow = true;
			this.weiCodeData = {
				Page: `pages/detail/detail`,
				Scene: `id=${id}`
			}
        },
        //排序变化触发方法
        sortChange(obj){
            this.ruleForm.salePriceSort = '';
            this.ruleForm.salesSort = '';
            this.ruleForm.totalStockSort = '';
            let Sort = '';
            if (obj.order == "ascending") {
                Sort = 0;
            }else if(obj.order == 'descending'){
                Sort = 1;
            }else if (obj.order == null) {
                Sort = '';
            }
            if (obj.prop == "salePrice") {
                this.ruleForm.salePriceSort = Sort;
            }else if (obj.prop == "totalStock") {
                this.ruleForm.salePriceSort = Sort;
            }else if (obj.prop == "soldCount") {
                this.ruleForm.salePriceSort = Sort;
            }
            this.getDataByType();
        },
        // 上下架
		async displyFun (record) {
			this.loading = true;
			try {
				let data = {
					goodsId: record.id,
					status: record.shelfStatus ? 1 : 0
				}
				let result = await productOpen(data)
				if (result.success) {
					this.$message({
						type: 'success',
						message: '操作成功!'
					});
				}else{
                    this.$message({
						type: 'error',
						message: result.alertMsg
					});
                }
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
				this.pageNo = 1;
				this.getDataByType();
			}
        },
        //删除
		delGoods (row) {
			this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {}).then(async () => {
				let goodsIds = [row.id]
				let result = await productDel(goodsIds);
				if (result.success) {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.getDataByType();
				} else {
					this.$message({
						type: 'error',
						message: result.alertMsg
					});
				}
			})
        },
        // 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getDataByType();
		},
		// 翻页
		handleCurrentChange (val) {
			this.pageNo = val;
			this.getDataByType();
		},
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background: #fff;
}
.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 10px;
	cursor: pointer;
}

.dialog {
	width: 100%;

	/deep/.el-dialog__header {
		border-bottom: 1px solid #ddd;
	}

	.content {
		padding: 0 30px;
		display: flex;
		flex-direction: row;

		.right {
			margin-left: 20px;
		}

		.text {
			margin-left: 20px;
			font-size: 14px;
			color: #999;
			line-height: 40px;
		}
	}

	.button {
		margin: 0 auto;
		width: 200px;
	}
}

.data-dialog {
	.value {
		line-height: 50px;
		font-size: 24px;
		color: #000;
	}

	.item {
		color: #666;
		margin-top: 10px;
	}
}
</style>
